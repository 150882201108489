exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-electrical-js": () => import("./../../../src/pages/electrical.js" /* webpackChunkName: "component---src-pages-electrical-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-1-js": () => import("./../../../src/pages/page1.js" /* webpackChunkName: "component---src-pages-page-1-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-romapay-js": () => import("./../../../src/pages/romapay.js" /* webpackChunkName: "component---src-pages-romapay-js" */),
  "component---src-pages-tshisimani-js": () => import("./../../../src/pages/tshisimani.js" /* webpackChunkName: "component---src-pages-tshisimani-js" */),
  "component---src-pages-xavier-js": () => import("./../../../src/pages/xavier.js" /* webpackChunkName: "component---src-pages-xavier-js" */)
}

